<script>
import { FormWizard, TabContent } from "@anivive/vue3-form-wizard";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from 'sweetalert2';
import router from '@/router';

import brandGetall from '../../../state/api/brandGetAll';
import UserTypeGetAll from "../../../state/api/UserTypeGetAll";
import allCountries from "../../../state/api/allCountries";
import userSaveOrUpdate from "../../../state/api/userSaveOrUpdate";


/**
 * Form wizard component
 */
export default {
  page: {
    title: "Form Wizard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, FormWizard, TabContent },
  setup(){

  },
  data() {
    return {
      title: "Add Brand Manager",
      items: [
        {
          text: "Users",
          href: "/",
        },
        {
          text: "Add Brand Manager",
          active: true,
        },
      ],
      UserTypeList : [],
      allCountriesList:[],
      brandList:[],
      userType:"",
      FirstName:"",
      LastName:"",
      UserName:"",
      CompanyName:"",
      Phone:"",
      language:"",
      country:"",
      selected: [],
      options: [],
      checkedNames: [],
      searchText:"",
      countryId:"",
      brandId:"",
      errorTxt:""
    };
  },
  methods:{
    async brandGetall(){
        const {getBrandGetAll} = brandGetall()
        const response = await getBrandGetAll();
        this.brandList = response.data;             
    },
    async UserTypeGetAll(){
        const {userTypeGet} = UserTypeGetAll()
        const response = await userTypeGet();
        this.UserTypeList = response.data;           
    },
    async allCountries(){
        const {allCountriesGet} = allCountries()
        const response = await allCountriesGet();
        this.allCountriesList = response.data;
        this.options = response.data;
    },
    getUpdateUser(){
        let data = this;
        if(!this.countryId){
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Please Select Country",
                showConfirmButton: false,
                timer: 1500
            })
            return false
        }
        else if(!this.brandId){
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Please Select Brand",
                showConfirmButton: false,
                timer: 1500
            })
            return false
        }
        else if(!this.CompanyName){
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Please Write Company Name",
                showConfirmButton: false,
                timer: 1500
            })
            return false
        }
        else if(!this.UserName){
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Please Write Email Address",
                showConfirmButton: false,
                timer: 1500
            })
            return false
        }
        else if(!this.FirstName){
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Please Write Your Name",
                showConfirmButton: false,
                timer: 1500
            })
            return false
        }
        else if(!this.LastName){
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Please Write Last Name",
                showConfirmButton: false,
                timer: 1500
            })
            return false
        }        
        let update = {
            "UserTypeId":3,
            "FirstName":this.FirstName,
            "LastName":this.LastName,
            "UserName":this.UserName,
            "CompanyName":this.CompanyName,
            "Phone":this.Phone,
            "countryId":this.countryId,
            "brandId":this.brandId,
            "IsActive":true,
            "IsMailAuthentication":true,
        }
        
        async function updateUser() {           
            const {saveOrUpdate} = userSaveOrUpdate()
            await saveOrUpdate(update).
            then(res=>{
                if(res.isSuccess){
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Your work has been saved",
                        showConfirmButton: false,
                        timer: 1500
                    })
                    router.push({name:'default'})
                }
                else{
                    data.errorTxt = res.errorMessage
                }
            })
        }
        updateUser();
    },
  },
  mounted(){
    this.UserTypeGetAll();
    this.allCountries();
    this.brandGetall();
  },
  computed: {
    // filteredList() {
    //     return this.allCountriesList.filter(item => {
    //         return item.name.toLowerCase().includes(this.searchText.toLowerCase())
    //     })
    // }
  },
};
</script>
<style scoped>
.form-multiselect .form-multiselect-list{
    max-height: 200px;
    overflow-y: scroll;
}
.form-multiselect .form-check-label{
    width: 90%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
            <div class="row">
                <div class="col-lg-6">
                    <div class="card-body">
                        <form-wizard color="#556ee6">
                            <tab-content icon="mdi mdi-account-circle">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group row mb-3">
                                            <label class="col-md-3 col-form-label" for="country"
                                                >{{$t('user.countrySelect')}} <span class="text-danger">*</span></label
                                            >
                                            <div class="col-md-9">
                                                <select class="form-control" v-model="countryId" required>
                                                    <option v-for="country in allCountriesList" :key="country.id" :value="country.id">{{country.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row mb-3">
                                            <label class="col-md-3 col-form-label" for="selectedBrand"
                                                >{{$t('user.BrandSelect')}} <span class="text-danger">*</span></label
                                            >
                                            <div class="col-md-9">
                                                <select class="form-control" v-model="brandId">
                                                    <option v-for="brand in brandList" :key="brand.id" :value="brand.id">{{brand.title}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row mb-3">
                                            <label class="col-md-3 col-form-label" for="CompanyName"
                                                >{{$t('user.CompanyName')}} <span class="text-danger">*</span></label
                                            >
                                            <div class="col-md-9">
                                                <input
                                                type="text"
                                                name="CompanyName"
                                                class="form-control"
                                                v-model="CompanyName"
                                                required
                                                />
                                            </div>
                                        </div>                                        
                                        <div class="form-group row mb-3">
                                            <label class="col-md-3 col-form-label" for="email"
                                                >{{$t('user.Email')}} <span class="text-danger">*</span></label
                                            >
                                            <div class="col-md-9">
                                                <input
                                                type="email"
                                                name="email"
                                                class="form-control"
                                                v-model="UserName"
                                                required
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row mb-3">
                                            <label class="col-md-3 col-form-label" for="FirstName"
                                                >{{$t('user.FirstName')}} <span class="text-danger">*</span></label
                                            >
                                            <div class="col-md-9">
                                                <input
                                                type="text"
                                                name="FirstName"
                                                class="form-control"
                                                v-model="FirstName"
                                                required
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row mb-3">
                                            <label class="col-md-3 col-form-label" for="LastName"
                                                >{{$t('user.LastName')}} <span class="text-danger">*</span></label
                                            >
                                            <div class="col-md-9">
                                                <input
                                                type="text"
                                                name="LastName"
                                                class="form-control"
                                                v-model="LastName"
                                                required
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row mb-3">
                                            <label class="col-md-3 col-form-label" for="Phone"
                                                >{{$t('user.Phone')}}</label
                                            >
                                            <div class="col-md-9">
                                                <input
                                                type="text"
                                                name="Phone"
                                                class="form-control"
                                                v-model="Phone"
                                                />
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <button type="submit" class="btn btn-primary me-1" @click="getUpdateUser">
                                            {{$t('user.SaveChanges')}}
                                            </button>
                                        </div>
                                        <div class="alert alert-danger mb-xl-0 mt-3" v-show="errorTxt != ''">
                                            {{ errorTxt }}
                                        </div>
                                    </div>
                                    <!-- end col -->
                                </div>
                                <!-- end row -->
                            </tab-content>
                        </form-wizard>
                    </div>
                    <!-- end card-body -->
                </div>
            </div>            
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>



